<template>
  <div id="datasetPicker">
    <h2>Choose Dataset(s)</h2>
    <div class="card p-2 mb-5">
      <div
        class="mb-2"
      >
        <span
          v-for="(crumb, i) in breadcrumbs"
          v-bind:key="i"
        >
          <b-badge
            pill
            variant="primary"
            @click="launchFolder(crumb, true)"
            class="clickable"
          >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== breadcrumbs.length"> / </span>
        </span>
      </div>
      <div class="scroll">
        <!-- FOLDERS -->
        <div class="list-group mb-2 folders">
          <div
            v-for="(folder, i) in $store.state.folders"
            v-bind:key="i"
            class="list-container"
          >
            <a
              @click="launchFolder(folder)"
              class="list-group-item list-group-item-action clickable mb-2 folder"
            >
              <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
              <p class="mb-1">{{ folder.description }}</p>
            </a>
          </div>
        </div><!-- /.list-group -->
        <!-- DATASETS -->
        <div class="list-group sets">
          <div
          v-for="(dataset, i) in $store.state.datasets"
          v-bind:key="i"
          class="list-container"
          >
            <a
              @click="launchDataset(dataset)"
              class="list-group-item list-group-item-action clickable handle mb-2"
            >
              <p class="lead mb-1"><i v-if="dataset.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i> {{ dataset.name }}</p>
              <p class="mb-1">{{ dataset.description }}</p>
            </a>
          </div>
        </div><!-- /.list-group -->
      </div><!-- /.scroll -->
    </div><!-- /.card -->
  </div><!-- /#dataset-picker -->
</template>

<script>
import axios from 'axios'

export default {
  computed: {
    breadcrumbs: {
      get () {
        return this.$store.state.breadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'breadcrumbs', value: value })
      }
    },
    chosenDatasets: {
      get () {
        return this.$store.state.chosenDatasets
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'chosenDatasets', value: value })
      }
    }
  },
  created () {
    this.fetchDatasets()
    this.fetchFolders()
  },
  methods: {
    fetchDatasets () {
      const timeStamp = new Date().getTime()
      const datasetsQuery = 'https://api.efficientvineyard.com/datasets/' + this.$route.params.farmId + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id

      this.$store.commit('setStateProperty', { property: 'farmId', value: this.$route.params.farmId })

      axios.get(datasetsQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'datasets', value: response.data })
      }).catch(err => {
        console.log(err.message)
      })
    },
    fetchFolders () {
      const timeStamp = new Date().getTime()
      const foldersQuery = 'https://api.efficientvineyard.com/folders/' + this.$route.params.farmId + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id
      axios.get(foldersQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'folders', value: response.data })
      }).catch(err => {
        console.log(err.message)
      })
    },
    launchFolder (folder, fromCrumb) {
      if (this.breadcrumbs.length < 1) {
        this.breadcrumbs = [{ name: 'Root', id: 'root' }]
      }
      if (fromCrumb) {
        const newBreadcrumbs = []
        this.breadcrumbs.some(crumb => {
          if (crumb.id !== folder.id) {
            newBreadcrumbs.push(crumb)
          } else {
            newBreadcrumbs.push(crumb)
            return true
          }
        })
        this.breadcrumbs = newBreadcrumbs
      } else {
        this.breadcrumbs.push(folder)
      }
      this.$store.commit('setStateProperty', { property: 'folder', value: folder })
      this.fetchDatasets()
      this.fetchFolders()
    },
    launchDataset (dataset) {
      this.chosenDatasets.push(dataset)
    }
  }
}
</script>

<style scoped>
.scroll {
  max-height: calc(100vh - 130px);
  overflow: scroll;
}
.card > .list-group {
  border-top: none;
  border-bottom: none;
}
.folder {
  background-color: #eee;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
