<template>
  <div id="chosenDatasets">
    <h2>Selected</h2>
    <div class="list-group">
      <a
        v-for="(dataset, i) in $store.state.chosenDatasets"
        v-bind:key="i"
        class="list-group-item list-group-item-action"
        v-bind:class="{ selected: dataset.selected }"
      >
        <p class="mb-1"><i class="fas fa-minus-circle text-danger clickable" @click="removeDataset(i)"></i> {{ dataset.name }}<br><small>{{ dataset.description }}</small></p>
        <p
          class="text-danger mb-1"
          v-if="!dataset.chosenHeaders"
        >Select at least one header.</p>
        <div
          v-if="dataset.headers"
          class="tags-container"
        >
          <!-- <span
            v-for="(header, me) in dataset.headers"
            v-bind:key="me"
            class="badge badge-pill badge-secondary mr-1"
          >
            {{ header }}
          </span> -->
          <span
            v-for="(header, me) in dataset.headers"
            v-bind:key="me"
          >
            <b-button
              size="sm"
              class="mr-1 mb-1"
              v-if="!dataset.chosenHeaders || !dataset.chosenHeaders[header]"
              @click="chooseHeader(header, i)"
            >
              {{ header }}
            </b-button>
            <b-button
              size="sm"
              class="mr-1 mb-1"
              variant="success"
              v-if="dataset.chosenHeaders && dataset.chosenHeaders[header]"
              @click="removeHeader(header, i)"
            >
              {{ header }}
            </b-button>
          </span>
        </div><!-- /.tags-container -->
      </a>
    </div><!-- /.list-group -->
  </div><!-- /#chosenDatasets -->
</template>

<script>
export default {
  computed: {
    chosenDatasets: {
      get () {
        return this.$store.state.chosenDatasets
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'chosenDatasets', value: value })
      }
    }
  },
  methods: {
    chooseHeader (header, datasetIndex) {
      if (!this.chosenDatasets[datasetIndex].chosenHeaders) {
        this.chosenDatasets[datasetIndex].chosenHeaders = {}
      }
      this.chosenDatasets[datasetIndex].chosenHeaders[header] = true
      this.$store.commit('joinDatasets')
    },
    removeHeader (header, datasetIndex) {
      delete this.chosenDatasets[datasetIndex].chosenHeaders[header]
      const numberOfHeaders = Object.keys(this.chosenDatasets[datasetIndex].chosenHeaders).length
      if (numberOfHeaders == 0) {
        delete this.chosenDatasets[datasetIndex].chosenHeaders
      }
      this.$store.commit('joinDatasets')
    },
    removeDataset (i) {
      delete this.chosenDatasets[i].chosenHeaders
      this.chosenDatasets.splice(i, 1)
      this.merges = []
      this.$store.commit('joinDatasets')
    }
  }
}
</script>