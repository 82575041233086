<template>
  <div id="alertMessage">
    <div
      class="alert"
      role="alert"
      :class="$store.state.alert.level"
    >
      <!-- <button @click="$store.commit('setAlert', { active: false })" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button> -->
      {{ $store.state.alert.message }}
    </div><!-- /.alert -->
  </div><!-- /#alert -->
</template>

<script>
export default {}
</script>

<style scoped>
#alertMessage {
  position: fixed;
  z-index: 99;
  top: 30px;
  left: 80px;
  width: calc(100% - 160px);
  text-align: center;
}
</style>
