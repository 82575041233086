import axios from 'axios'

export default {
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  setAlert (state, value) {
    state.alert = value
    if (value.timeout > 0) {
      setTimeout(function () {
        state.alert.active = false
      }, value.timeout)
    }
  },
  joinDatasets (state) {
    state.allGeoJsonData = []
    state.allHeaders = []
    state.allJsonData = {}
    state.allJsonDataArray = []
    state.vectors = []
    let self = state
    // Get all geoJson data
    let getGeoJsonPromises = []

    async function getGeoJson (geoJsonURL, datasetName, datasetHeader) {
      await axios.get(geoJsonURL).then(response => {
        let datasetDataObj = response.data
        datasetDataObj.name = datasetName
        datasetDataObj.header = datasetHeader
        datasetDataObj.headerName = datasetHeader + '_' + datasetName
        self.allGeoJsonData.push(datasetDataObj)
        self.allHeaders.push(datasetDataObj.headerName)
      })
    }

    state.chosenDatasets.forEach(dataset => {
      dataset.headers.forEach(header => {
        if (dataset.chosenHeaders && dataset.chosenHeaders[header]) {
          let getGeoJsonPromise = getGeoJson(dataset.file.geoJsonURL, dataset.name, header)
          getGeoJsonPromises.push(getGeoJsonPromise)
        }
      })
    })

    Promise.all(getGeoJsonPromises).then(() => {
      for (let i = 0; i < self.allGeoJsonData.length; i++) {
        self.allGeoJsonData[i].features.forEach(feature => {
          let lon = feature.geometry.coordinates[0]
          let lat = feature.geometry.coordinates[1]
          let uniqueId = lon.toString() + lat.toString()
          // If this uniqueId exists, just add the data. Otherwise, create new row item
          // if (self.allJsonData.hasOwnProperty(uniqueId)) {
          if (Object.prototype.hasOwnProperty.call(self.allJsonData, uniqueId)) {
            self.allJsonData[uniqueId][self.allGeoJsonData[i].headerName] = feature.properties[self.allGeoJsonData[i].header]
          } else {
            self.allJsonData[uniqueId] = {
              longitude: lon,
              latitude: lat
            }
            self.allHeaders.forEach(headerItem => {
              self.allJsonData[uniqueId][headerItem] = false
            })
            self.allJsonData[uniqueId][self.allGeoJsonData[i].headerName] = feature.properties[self.allGeoJsonData[i].header]
          }
        })
      }

      // Loop through jsonData object and turn into an array
      let allJsonDataKeys = Object.keys(self.allJsonData)
      allJsonDataKeys.forEach(key => {
        self.allJsonDataArray.push(self.allJsonData[key])
      })

      // Remove any row that has false or non-numeric values
      for (let i = self.allJsonDataArray.length - 1; i >= 0; i--) {
        let passTest = true
        self.allHeaders.forEach(header => {
          if (!self.allJsonDataArray[i][header] || isNaN(self.allJsonDataArray[i][header])) {
            passTest = false
          }
        })
        if (!passTest) {
          self.allJsonDataArray.splice(i, 1)
        }
      }

      // Setup vectors
      for (let i = 0; i < self.allJsonDataArray.length; i++) {
        let vector = []
        self.allHeaders.forEach(header => {
          vector.push(self.allJsonDataArray[i][header])
        })
        self.vectors.push(vector)
      }
    })
  }
}
