<template>
  <div id="chooseDatasets">
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-4">
          <DatasetPicker />
        </div><!-- /.col-4 -->
        <div
          class="col-4"
          v-if="$store.state.chosenDatasets.length > 0"
        >
          <ChosenDatasets />
        </div><!-- /.col-4 -->
        <div
          class="col-4"
          v-if="$store.state.chosenDatasets.length > 0"
        >
          <ProcessSettings />
        </div><!-- /.col-4 -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    <PreviewClusters v-if="$store.state.previewClusters" />
  </div><!-- /#chooseDatasets-->
</template>

<script>
import ChosenDatasets from './ChosenDatasets.vue'
import DatasetPicker from './DatasetPicker.vue'
import PreviewClusters from './PreviewClusters.vue'
import ProcessSettings from './ProcessSettings.vue'

export default {
  components: {
    ChosenDatasets,
    DatasetPicker,
    PreviewClusters,
    ProcessSettings
  }
}
</script>
