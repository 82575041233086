import axios from 'axios'
const kmeans = require('node-kmeans')

export default {
  post ({ commit, state }) {
    commit('setStateProperty', { property: 'posting', value: true })
    const postName = state.post.name
    const postGeoJson = JSON.stringify(state.clusteredGeoJson)
    const postObject = {
      name: postName,
      createdBy: 'multivariate_plugin',
      farmId: state.farmId,
      geoJson: postGeoJson,
      folderId: state.folder.id,
      vizSettings: {
        colorHeader: 'zone',
        colorBy: 'valueBased',
        zoneClassification: 'categorical'
      }
    }
    axios.post('https://api.efficientvineyard.com/post/geojson', postObject).then(response => {
      if (response.data.success) {
        commit('setStateProperty', { property: 'posting', value: false })
        commit('setAlert', { active: true, message: 'This new dataset has been added to your myEV farm!', level: 'alert-success', timeout: 4000 })
        commit('setStateProperty', { property: 'pickFolder', value: false })
      } else {
        commit('setAlert', { active: true, message: 'There was an issue posting your dataset to myEV. The dataset may have been too large. Consider downloading directly and manually uploading to myEV.', level: 'alert-danger', timeout: 8000 })
      }
    }).catch(err => {
      console.log(err)
      commit('setAlert', { active: true, message: 'There was an issue posting your dataset to myEV. The dataset may have been too large. Consider downloading directly and manually uploading to myEV.', level: 'alert-danger', timeout: 12000 })
    })
  },
  processKmeans ({ commit, state }) {
    console.log('Process kmeans')
    commit('setStateProperty', { property: 'previewClusters', value: false })
    kmeans.clusterize(state.vectors, { k: state.zoneCount }, (err, res) => {
      if (err) {
        console.error(err)
      } else {
        console.log('Clusters Generated!')
        console.log(res)
        console.log(state)
        let geoJsonObj = {
          type: 'FeatureCollection',
          features: []
        }
        // Lopp clusters
        res.forEach((cluster, i) => {
          // Loop clusterInd
          cluster.clusterInd.forEach(ind => {
            // Create feature using the cluster data paired with state.allJSONDataArray
            let featureObj = {
              type: 'Feature',
              properties: state.allJsonDataArray[ind],
              geometry: {
                coordinates: [],
                type: 'Point'
              }
            }
            // Push Coordinates first
            featureObj.geometry.coordinates.push(state.allJsonDataArray[ind].longitude)
            featureObj.geometry.coordinates.push(state.allJsonDataArray[ind].latitude)
            // Remove lattitude longitude properties
            delete featureObj.properties.longitude
            delete featureObj.properties.latitude
            // Add cluster zone number to properties
            featureObj.properties.cluster = i
            featureObj.properties.zone = i + 1
            // Push the feature to the geoJsonObj
            geoJsonObj.features.push(featureObj)
          })
        })
        // Commit the geoJsonObj to state.clusteredGeoJson
        commit('setStateProperty', { property: 'clusteredGeoJson', value: geoJsonObj })
        commit('setStateProperty', { property: 'previewClusters', value: true })
      }
    })
  }
}
