import { createRouter, createWebHistory } from 'vue-router'
import ChooseDatasets from '../components/ChooseDatasets.vue'

const routes = [
  {
    path: '/:farmId',
    name: 'ChooseDatasets',
    component: ChooseDatasets
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
