<template>
  <div id="previewClusters">
    <div class="o-modal">
      <div class="o-modal-content big">
        <div class="o-modal-header">
          <a
            @click="closeClusterPreview()"
            class="close clickable"
          ><i class="far fa-times"></i></a>
          <p class="lead">Cluster Preview</p>
        </div><!-- /.o-modal-header -->
        <!-- THE MAP PREVIEW -->
        <div id="map" class="map"></div>

        <div class="o-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-8">
                <button
                  @click="$store.commit('setStateProperty', { property: 'pickFolder', value: true })"
                  class="btn btn-success mr-1 w-100"
                >
                  Save to myEV
                </button>
              </div><!-- /.col-6 -->
              <div class="col-4">
                <button
                  @click="download()"
                  class="btn btn-primary mr-1 w-100"
                >
                  Download
                </button>
              </div><!-- /.col-6 -->
            </div><!-- /.row -->
          </div><!-- /.container -->
        </div><!-- /.o-modal-footer -->
      </div><!-- /.o-modal-content -->
    </div><!-- /.o-modal -->
    <FolderPicker v-if="$store.state.pickFolder" />
  </div><!-- /#previewClusters -->
</template>

<script>
import FolderPicker from './FolderPicker.vue'

export default {
  components: {
    FolderPicker
  },
  mounted () {
    console.log('Loading Map')
    this.initMap()
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'mapObject', value: value})
      }
    }
  },
  methods: {
    download () {
      // Download for now
      var a = document.createElement('a')
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(this.$store.state.clusteredGeoJson)], { type: 'application/json' })
      )
      a.download = 'kmeansZones.geojson'
      a.click()
    },
    closeClusterPreview () {
      setTimeout(() => {
        this.$store.commit('joinDatasets')
      }, 300)
      this.$store.commit('setStateProperty', { property: 'previewClusters', value: false })
    },
    initMap () {
      mapboxgl.accessToken = 'pk.eyJ1IjoibGVyZ3AiLCJhIjoiY2p4bmI1NzNzMGN0MTNjcGx4cjF4eDBtdSJ9.2C0FEHhNZ-jGd7jgIRTrEQ' // eslint-disable-line
      this.mapObject = new mapboxgl.Map({ // eslint-disable-line
        container: 'map',
        style: 'mapbox://styles/lergp/ckbb0rqb0074k1iojtfyqy9id',
        center: [-95, 40],
        zoom: 3
      })
      this.mapObject.on('load', () => {
        this.mapDataset()
      }) // map.on load
    },
    mapDataset () {
      let mapObject = this.mapObject
      // Add GeoJSON dataset preview
      mapObject.addSource('datasetPreviewGeojson', {
        type: 'geojson',
        data: this.$store.state.clusteredGeoJson
      })
      let circlePaint = { 'circle-radius': this.$store.state.defaults.pointStops, 'circle-color': '#ffc107' }
      mapObject.addLayer({
        id: 'datasetPreview',
        type: 'circle',
        source: 'datasetPreviewGeojson',
        layout: {},
        paint: circlePaint
      })
      mapObject.fitBounds(turf.bbox(this.$store.state.clusteredGeoJson), { // eslint-disable-line
        animate: false,
        padding: 20
      })
      this.visualizeDataset()
    },
    visualizeDataset() {
      let mapObject = this.mapObject
      const colors =   [
        '#953c22',
        '#b65643',
        '#d67431',
        '#eea82d',
        '#fbe735',
        '#7ce92c',
        '#23ce34',
        '#27a57e',
        '#1f738c',
        '#1a3462',
        '#000018'
      ]
      let color = [
        'match',
        ['get', 'cluster']
      ]
      for (let i = 0; i < this.$store.state.zoneCount; i++) {
        color.push(i)
        let colorIndex = Math.floor((colors.length / this.$store.state.zoneCount) * i)
        console.log(colorIndex)
        color.push(colors[colorIndex])
      }
      color.push('#333')
      mapObject.setPaintProperty('datasetPreview', 'circle-color', color)
    }
  }
}
</script>

<style>
#map {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
}
</style>