<template>
  <div id="folderPicker">
    <div class="o-modal mb-5">
      <div class="o-modal-content">
        <div class="o-modal-header">
          <a
            @click="$store.commit('setStateProperty', { property: 'pickFolder', value: false })"
            class="close clickable"
          ><i class="far fa-times"></i></a>
          <p class="lead">Choose Folder & Name</p>
        </div><!-- /.o-modal-header -->

        <p class="text-center mb-1 mt-4 pt-3"><strong>Choose a Location</strong></p>
        <div
          class="mb-2"
        >
          <span
            v-for="(crumb, i) in breadcrumbs"
            v-bind:key="i"
          >
            <b-badge
              pill
              variant="primary"
              @click="launchFolder(crumb, true)"
              class="clickable"
            >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== breadcrumbs.length"> / </span>
          </span>
        </div>
        <div class="card p-2 scroll">
          <!-- FOLDERS -->
          <div class="list-group mb-2 folders" v-if="!loadingFolders">
            <div
              v-for="(folder, i) in $store.state.folders"
              v-bind:key="i"
              class="list-container"
            >
              <a
                @click="launchFolder(folder)"
                class="list-group-item list-group-item-action clickable mb-2 folder"
              >
                <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
                <p class="mb-1">{{ folder.description }}</p>
              </a>
            </div>
          </div><!-- /.list-group -->
          <!-- DATASETS -->
          <div class="list-group sets" v-if="!loadingDatasets">
            <div
            v-for="(dataset, i) in $store.state.datasets"
            v-bind:key="i"
            class="list-container"
            >
              <a class="list-group-item list-group-item-action handle mb-2">
                <p class="lead mb-1"><i v-if="dataset.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i> {{ dataset.name }}</p>
                <p class="mb-1">{{ dataset.description }}</p>
              </a>
            </div>
          </div><!-- /.list-group -->
        </div><!-- /.card -->
        <p class="text-center mb-1 mt-2"><strong>Name the Dataset</strong></p>
        <b-form-group
          class="mt-2"
        >
          <b-form-input v-model="post.name"></b-form-input>
        </b-form-group>
        <div class="o-modal-footer">
          <div class="float-right">
            <button
              v-if="!$store.state.posting"
              @click="$store.dispatch('post')"
              class="btn btn-success mr-1 w-100"
            >
              Save to myEV
            </button>
            <b-btn
              v-if="$store.state.posting"
              variant="light"
            ><i class="fas fa-sync fa-spin"></i> Adding now...</b-btn>
          </div><!-- /.float-right -->
        </div><!-- /.o-modal-footer -->
      </div><!-- /.o-modal-content -->
    </div><!-- /.o-modal -->
  </div><!-- /#dataset-picker -->
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      loadingFolders: true,
      loadingDatasets: true
    }
  },
  computed: {
    breadcrumbs: {
      get () {
        return this.$store.state.breadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'breadcrumbs', value: value })
      }
    },
    post: {
      get () {
        return this.$store.state.post
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'post', value: value })
      }
    }
  },
  created () {
    this.fetchDatasets()
    this.fetchFolders()
  },
  methods: {
    fetchDatasets () {
      this.loadingDatasets = true
      const timeStamp = new Date().getTime()
      const datasetsQuery = 'https://api.efficientvineyard.com/datasets/' + this.$route.params.farmId + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id

      axios.get(datasetsQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'datasets', value: response.data })
        this.loadingDatasets = false
      }).catch(err => {
        console.log(err.message)
      })
    },
    fetchFolders () {
      this.loadingFolders = true
      const timeStamp = new Date().getTime()
      const foldersQuery = 'https://api.efficientvineyard.com/folders/' + this.$route.params.farmId + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.folder.id

      axios.get(foldersQuery).then(response => {
        this.$store.commit('setStateProperty', { property: 'folders', value: response.data })
        this.loadingFolders = false
      }).catch(err => {
        console.log(err.message)
      })
    },
    launchFolder (folder, fromCrumb) {
      if (this.breadcrumbs.length < 1) {
        this.breadcrumbs = [{ name: 'Root', id: 'root' }]
      }
      if (fromCrumb) {
        const newBreadcrumbs = []
        this.breadcrumbs.some(crumb => {
          if (crumb.id !== folder.id) {
            newBreadcrumbs.push(crumb)
          } else {
            newBreadcrumbs.push(crumb)
            return true
          }
        })
        this.breadcrumbs = newBreadcrumbs
      } else {
        this.breadcrumbs.push(folder)
      }
      this.$store.commit('setStateProperty', { property: 'folder', value: folder })
      this.fetchDatasets()
      this.fetchFolders()
    }
  }
}
</script>

<style scoped>
/* #folderPicker {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 z-index: 9;
} */
.scroll {
  overflow: scroll;
  max-height: calc(100% - 220px);
}
.card > .list-group {
  border-top: none;
  border-bottom: none;
}
.folder {
  background-color: #eee;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
