import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store.js'

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import process from 'process'

import './assets/scss/app.scss'

window.process = process

createApp(App).use(store).use(router).use(BootstrapVue3).mount('#app')