<template>
  <div id="app">
    <router-view />
    <transition name="fade">
      <RingLoader
        v-if="$store.state.spinner"
        :color="'#61539b'"
        :size="'50px'"
        id="spinner"
      ></RingLoader>
    </transition>
    <AlertMessage v-if="$store.state.alert.active" />
  </div><!-- /#app -->
</template>

<script>
import AlertMessage from './components/AlertMessage.vue'
import RingLoader from 'vue-spinner/src/RingLoader'

export default {
  components: {
    AlertMessage,
    RingLoader
  }
}
</script>

<style scoped>
#spinner {
  z-index: 0;
  background-color: #eee;
}

.fade-enter-active {
  transition: opacity 0s;
}

.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>