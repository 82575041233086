import { createStore } from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'

export const store = createStore({
  state: {
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    allHeaders: [],
    allJsonDataArray: [],
    allGeoJsonData: [],
    allJsonData: {},
    breadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    clusteredGeoJson: {},
    chosenDatasets: [],
    datasets: [],
    defaults: {
      pointStops: {
        stops: [[15, 1], [16, 2], [17, 3], [18, 6], [20, 20], [23, 50]]
      }
    },
    farmId: false,
    folder: {
      id: 'root',
      name: 'Root'
    },
    folders: [],
    mapObject: {},
    pickFolder: false,
    post: {
      name: 'K-Means Analysis'
    },
    posting: false,
    previewClusters: false,
    spinner: false,
    vectors: [],
    zoneCount: 3
  },
  actions: actions,
  mutations: mutations,
  getters: {},
  modules: {}
})
