<template>
  <div id="processSettings">
    <h2>Process Settings</h2>
    <div class="card p-2 mb-5">
      <p><strong>Common Points:</strong> {{ $store.state.vectors.length }}</p>
      <label>Zone Count</label>
      <b-form-select
        v-model="zoneCount"
        :options="zoneCountOpts"
      ></b-form-select>
      <b-button
        variant="success"
        @click="$store.dispatch('processKmeans')"
        class="mt-4"
      >Run Process</b-button>
    </div><!-- /.card -->
  </div><!-- /#processSettings -->
</template>

<script>
export default {
  data () {
    return {
      zoneCountOpts: [
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' }
      ]
    }
  },
  computed: {
    zoneCount: {
      get () {
        return this.$store.state.zoneCount
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'zoneCount', value: value })
      }
    }
  },
  created () {
    this.zoneCount = 3
  }
}
</script>